import React from "react"
import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform:scale(1) translateY(-100px);
  }
  100% {
    opacity: 1;
    transform:scale(1) translateY(0px);
  }
`
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 15vw;
  box-sizing: border-box;
  justify-content: space-between;
`

const StyledTitle = styled.h1`
  font-size: 60px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 100%;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 30px;
  }
`

const Foot = styled.div`
opacity:0;
  animation: ${fadeIn} 1.5s ease-out;
  flex-wrap: wrap-reverse;
  text-transform: uppercase;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation-fill-mode:forwards;
  animation-delay: 0.3s;
`
const Header = styled(Foot)`
  animation-delay: 0s;
`

const Logo = styled.div`
  height: 100px;
  width: 100px;
  background: linear-gradient(180deg, #ffc39a 0%, #f87700 100%);
  border-radius: 50px;
  margin: 50px 0;
`
export default function Home() {
  return (
    <Container>
      <Header>
        <div>
          <StyledTitle>Contensia</StyledTitle>
          <StyledTitle>business </StyledTitle>
          <StyledTitle>development</StyledTitle>
        </div>
        <div>
          <Logo />
        </div>
      </Header>
      <Foot>
  
        <div>
          <p>Business Development</p>
          <p>Media</p>
          <p>Branding</p>
          <p>Invention</p>
        </div>
        <div>
          <p>info@contensia.com</p>
        </div>
      </Foot>
    </Container>
  )
}
